import styled from 'styled-components'
import { breakpoint, color } from '../../../configs/utilities'

export const PageTitle = styled.h1`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${color.black};

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 8vw;
    line-height: 10vw;
  }

  @media screen and (min-width: ${breakpoint.sm}) {
    font-size: 34px;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 38px;
    line-height: 50px;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    font-size: 42px;
    line-height: 50px;
  }
`

export const SectionTitle = styled.p`
  font-family: 'Playfair Display', serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  color: ${color.black};

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 7vw;
    line-height: 10vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 32px;
    line-height: 38px;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    font-size: 36px;
    line-height: 44px;
  }
`

export const SectionDescription = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: ${color.black};

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 4vw;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    font-size: 16px;
    line-height: 30px;
  }
`

export const SectionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0;
  color: ${color.black};


  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 4vw;
  }
`

export const CreateInfo = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  text-transform: capitalize;
  color: ${color.black};

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 4vw;
    line-height: 10vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 14px;
    line-height: 30px;
  }
`

export const CreateTitle = styled.h1`
  font-weight: 700;
  font-size: 45px;
  line-height: 60px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${color.black};


  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 12vw;
    line-height: 12vw;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 64px;
    line-height: 78px;
    max-width: 450px;
  }
`

export const CreateSubTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  opacity: 0.7;
  color: ${color.black};

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 8vw;
    line-height: 10vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 22px;
  }
`

export const CreateDescription = styled.p`
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.5;

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 5vw;
    line-height: 8vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 14px;
  }
`

export const CreateText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${color.black};
  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 4vw;
  }
  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 600px;
  }
`
